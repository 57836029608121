<template>
  <div class="green_message">
    <p class="p-4">{{ $t('form_was_submitted') }}</p>
  </div>
</template>


<script>
export default {
  name: "FormSubmitComponent"
}
</script>

<style lang="scss" scoped>

.green_message {
  width: 1300px;
  height: 50px;
  position: absolute;
  top: 80px;
  left: calc((100% - 1300px)/2);
  background-color: #fcfff5;
  border: 2px solid #a3c293;
  border-radius: 5px;


  p.p-4 {
    font-weight: 500;
    color: #255b26;
    text-align: center;
    margin-top: 8px;
  }
}
@media (max-width: 1450px) {
  .green_message {
    width: 90%;
    left: 5%;
  }
}
@media (max-width: 576px) {
  .green_message {
    top: 55px;
    height: 40px;

    p.p-4 {
      margin-top: 4px;
    }
  }
}
</style>