<template>
  <div class="home_systems_container">
    <div class="system_elem">
      <div class="img_container" v-if="width > 1350">
        <img src="@/assets/images/home/home_2.png" loading="lazy">
      </div>
      <div class="img_container" v-if="width <= 1350">
        <img src="@/assets/images/home/home_2_b.png" loading="lazy">
      </div>
      <div class="description">
        <a :href="publicPath + 'central_management_system.pdf'" class="black_button" download="" v-if="width > 1350">{{ $t('download_pdf') }}</a>
        <div class="text_container">
          <h3>{{ $t('street_lighting_control_system') }}</h3>
          <br>
          <br>
          <p class="p-4">{{ $t('home_paragraph_2') }}</p>
          <br>
          <br>
          <div class="bottom_buttons">
            <router-link class="logo_container" to="/products/1" @click="changeNavBar('products')">
              <div class="find_out_more">
                <p class="p-4">{{ $t('find_out_more') }}</p>
                <div class="arrow_button">
                  <div>
                    <img src="@/assets/arrow.png" alt="" loading="lazy">
                  </div>
                </div>
              </div>
            </router-link>
            <a :href="publicPath + 'central_management_system.pdf'" class="black_button" download="" v-if="width <= 1350">{{ $t('download_pdf') }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="system_elem" v-if="width > 1350">
      <div class="description">
        <a :href="publicPath + 'luminaire_controller.pdf'" class="black_button" download="">{{ $t('download_pdf') }}</a>
        <div class="text_container">
          <h3>{{ $t('home_luminaire_controller') }}</h3>
          <br>
          <br>
          <p class="p-4">{{ $t('home_paragraph_3') }}</p>
          <br>
          <br>
          <router-link class="logo_container" to="/products/3" @click="changeNavBar('products')">
            <div class="find_out_more">
              <p class="p-4">{{ $t('find_out_more') }}</p>
              <div class="arrow_button">
                <div>
                  <img src="@/assets/arrow.png" alt="" loading="lazy">
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="img_container">
        <img src="@/assets/images/home/home_3.png" loading="lazy">
      </div>
    </div>

    <div class="system_elem" v-if="width <= 1350">
      <div class="img_container">
        <img src="@/assets/images/home/home_3_b.png" loading="lazy">
      </div>
      <div class="description">
        <div class="text_container">
          <h3>{{ $t('home_luminaire_controller') }}</h3>
          <br>
          <br>
          <p class="p-4">{{ $t('home_paragraph_3') }}</p>
          <br>
          <br>
          <div class="bottom_buttons">
            <router-link class="logo_container" to="/products/3" @click="changeNavBar('products')">
              <div class="find_out_more">
                <p class="p-4">{{ $t('find_out_more') }}</p>
                <div class="arrow_button">
                  <div>
                    <img src="@/assets/arrow.png" alt="" loading="lazy">
                  </div>
                </div>
              </div>
            </router-link>
            <a :href="publicPath + 'luminaire_controller.pdf'" class="black_button" download="">{{ $t('download_pdf') }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="system_elem">
      <div class="img_container" v-if="width > 1350">
        <img src="@/assets/images/home/home_4.png" loading="lazy">
      </div>
      <div class="img_container" v-if="width <= 1350">
        <img src="@/assets/images/home/home_4_b.png" loading="lazy">
      </div>
      <div class="description">
        <a :href="publicPath + 'segment_controller.pdf'" class="black_button" download="" v-if="width > 1350">{{ $t('download_pdf') }}</a>
        <div class="text_container">
          <h3>{{ $t('home_segment_controller') }}</h3>
          <br>
          <br>
          <p class="p-4">{{ $t('home_paragraph_4') }}</p>
          <br>
          <br>
          <div class="bottom_buttons">
            <router-link class="logo_container" to="/products/2" @click="changeNavBar('products')">
              <div class="find_out_more">
                <p class="p-4">{{ $t('find_out_more') }}</p>
                <div class="arrow_button">
                  <div>
                    <img src="@/assets/arrow.png" alt="" loading="lazy">
                  </div>
                </div>
              </div>
            </router-link>
            <a :href="publicPath + 'segment_controller.pdf'" class="black_button" download="" v-if="width <= 1350">{{ $t('download_pdf') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HomeSystems",
  methods: {
    changeNavBar(place) {
      this.emitter.emit("update-menu", place)
    },
    onResize() {
      this.width = window.innerWidth
      this.height = window.innerHeight
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize)
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize)
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      width: window.innerWidth,
      height: window.innerHeight
    }
  },
}
</script>

<style lang="scss" scoped>

.home_systems_container {
  border: 1px solid black;
}
.img_container, .description {
  width: 50%;
}
.img_container > img {
  width: 100%;
  display: block;
}
.system_elem {
  width: 100%;
  display: flex;
}
a.black_button {
  width: 179px;
  height: 49px;
  float: right;
  position: relative;
  top: 24px;
  right: 24px;
}
.text_container {
  width: 65%;
  margin: min(12vh, 96px) auto 0 auto;
}
.find_out_more {
  display: flex;
  align-items: center;
  color: black;

  .arrow_button {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;

    img {
      width: 18px;
    }
  }
  &:active {
    .arrow_button {
      border: 1px solid white;
      filter: invert(100);

    }
  }
}
@media (max-width: 1350px) {
  .home_systems_container {
    border: none;
  }
  .system_elem {
    display: block;
  }
  .img_container, .description {
    width: 100%;
  }
  .text_container {
    width: 100%;
    margin: min(10vh, 80px) auto;
  }
  a.black_button {
    float: none;
    position: static;
  }
  .bottom_buttons {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .bottom_buttons {
    display: block;
  }
  a.black_button {
    margin-top: min(5vh, 40px);
    width: 100%;
  }
}
</style>