<template>
  <div class="standard_main_container footer_base">
    <router-link class="logo_container outer_logo" to="/" @click="changeNavBar('home')">
      <img src="@/assets/mauglo-logo.png" alt="">
    </router-link>

    <div class="contact_us_content">
      <router-link class="logo_container inner_logo" to="/" @click="changeNavBar('home')">
        <img src="@/assets/mauglo-logo.png" alt="">
      </router-link>

      <div class="products_container">
        <p class="caps-s">{{ $t('products') }}</p>
        <router-link class="logo_container first_router_link" to="/products/1" @click="changeNavBar('products')">
          <p class="p-1 first_router_link">{{ $t('control_panel') }}</p>
        </router-link>
        <router-link class="logo_container" to="/products/2" @click="changeNavBar('products')">
          <p class="p-1">{{ $t('segment_controller') }}</p>
        </router-link>
        <router-link class="logo_container" to="/products/3" @click="changeNavBar('products')">
          <p class="p-1">{{ $t('luminaire_controller') }}</p>
        </router-link>
        <router-link class="logo_container" to="/products/4" @click="changeNavBar('products')">
          <p class="p-1">{{ $t('motion_sensor') }}</p>
        </router-link>
        <router-link class="logo_container" to="/products/5" @click="changeNavBar('products')">
          <p class="p-1">{{ $t('surge_protector') }}</p>
        </router-link>
        <router-link class="logo_container" to="/products/6" @click="changeNavBar('products')">
          <p class="p-1">{{ $t('wifi_hotspot') }}</p>
        </router-link>
      </div>
      <div class="contacts_container">
        <p class="caps-s">{{ $t('contacts') }}</p>
        <p class="p-1">{{ $t('company') }}: {{ $t('company_name') }}</p>
        <p class="p-1">{{ $t('reg_nr') }}: {{ $t('company_reg_nr') }}</p>
        <p class="p-1">{{ $t('address') }}: {{ $t('company_address_1') }}</p>
        <p class="p-1">{{ $t('company_address_2') }}</p>
        <p class="p-1">{{ $t('mob') }}: <a :href="`tel:${$t('company_mob')}`">{{ $t('company_mob') }}</a></p>
        <p class="p-1">{{ $t('email') }}: <a href="mailto:office@mauglo.com">office@mauglo.com</a></p>
        <p class="p-1 empty_elem"></p>
        <p class="p-1"><a href="https://form.asana.com/?k=9tHW-0KTWyhbgz1nutdSWw&d=251190582465178" target="_blank">Claims & Warranty</a></p>
      </div>
      <div class="get_in_touch_container">
        <p class="caps-s">{{ $t('get_in_touch') }}</p>
        <form action="">
          <label class="form_block" for="name">{{ $t('name') }}</label>
          <input class="form_block" name="name" id="name" type="text" required>
          <label class="form_block" for="e-mail">{{ $t('email') }}</label>
          <input class="form_block" name="e-mail" id="e-mail" type="text" required>
          <label class="form_block" for="message">{{ $t('message') }}</label>
          <input class="form_block" name="message" id="message" type="text" required>
          <div class="checkbox_box form_block">
            <input class="checkbox_input" type="checkbox" name="rules" id="rules" required>
            <label class="checkbox_label" for="rules">
              {{ $t('form_rules') }}
              <router-link to="/privacy_rules" @click="changeNavBar('home')">
                <a target="_blank">{{ $t('privacy_policy') }}</a>.
              </router-link>
            </label>
          </div>
          <button class="form_block" type="button" @click="submitForm">{{ $t('submit') }}</button>
        </form>
      </div>
    </div>
    <hr>
    <div class="footer_logo_container">
      <div class="social_icon_container">
        <a href="#" target="_blank">
          <div class="social_icons">
            <img src="@/assets/facebook-logo.png">
          </div>
        </a>
        <a href="#" target="_blank">
          <div class="social_icons">
            <img class="larger_icon" src="@/assets/instagram-logo.png">
          </div>
        </a>
        <a href="#" target="_blank">
          <div class="social_icons">
            <img class="smaller_icon" src="@/assets/linkedin.png">
          </div>
        </a>
        <a href="#" target="_blank">
          <div class="social_icons">
            <img src="@/assets/twitter-logo.png">
          </div>
        </a>
      </div>
      <div class="certificate_logos">
        <div>
          <img class="certificate_img_1 margin_auto" src="@/assets/image23.png">
        </div>
        <div>
          <img class="certificate_img_2" src="@/assets/norway-grant.png">
        </div>
      </div>
      <div class="short_copy_right">
        <img class="copyrights_logo" src="@/assets/copyright-logo.png">
      </div>
      <div class="long_copy_right">
        <img class="copyrights_logo" src="@/assets/copyright-logo-full.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {
    changeNavBar(place) {
      this.emitter.emit("update-menu", place)
    },
    submitForm() {
      this.emitter.emit("submit-form")
    }
  }
}
</script>

<style lang="scss" scoped>

  .bottom_placement {
    position: absolute;
    bottom: 0;
  }

  .empty_elem {
    height: 23px;
  }

  .standard_main_container.footer_base {
    padding-top: 0;
  }
  a {
    text-underline-position: under;
    color: black;
  }
  .contact_us_content {
    width: 100%;
    display: flex;
    margin: min(6vh, 48px) 0;
  }
  .logo_container {
    width: 24%;
  }
  .logo_container > img {
    width: 130px;
  }
  .products_container {
    width: 17%;

    router-link {
      text-decoration: none;
    }
    .p-1 {
      text-transform: uppercase;
    }
  }
  .p-1 {
    margin-top: 10px;
  }
  p.p-1:nth-child(2) {
    margin-top: 30px;
  }
  .caps-s {
    letter-spacing: 0.395em;
  }
  .contacts_container {
    width: 25%;
    position: relative;
  }
  .get_in_touch_container {
    width: 34%;

    .form_block {
      display: block;
    }
    .checkbox_box {
      display: flex;
      align-items: center;
    }
    .checkbox_input {
      width: 17px;
      height: 17px;
      margin-right: 15px;
      margin-left: 3px;
    }
    .checkbox_label {
      display: inline-block;
      width: calc(100% - 35px);
      font-size: 12px;
    }
  }
  .form_block:nth-child(1) {
    margin-top: 30px;
  }
  label.form_block {
    text-transform: uppercase;
  }
  label.form_block:nth-child(1) {
    margin-bottom: 10px;
  }
  label.form_block:nth-child(3), label.form_block:nth-child(5) {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .form_block:nth-child(6) {
    margin-bottom: 30px;
  }
  label > a {
    color: black;
  }
  input[type="text"] {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    background-color: map.get($colors, "light-background-color");

    &:focus {
      outline: none;
    }
  }
  button {
    width: 130px;
    height: 50px;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    background-color: black;
    margin-top: 30px;

    &:hover {
     cursor: pointer;
    }
    &:focus {
      color: black;
      background-color: white;
      border: 1px solid black;
    }
  }
  .footer_logo_container {
    width: 100%;
    //height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .certificate_img_1, .certificate_img_2 {
      margin: 30px auto;
    }

    .social_icon_container {
      width: 220px;
      display: flex;

      a:nth-child(1), a:nth-child(2), a:nth-child(3) {
        margin-right: 20px;
      }

      .social_icons {
        width: 40px;
        height: 40px;
        background-color: black;
        border-radius: 20px;

        img {
          width: 20px;
          position: relative;
          top: 10px;
          left: 10px;
        }
        .smaller_icon {
          width: 18px;
          top: 11px;
          left: 11px;
        }
        .larger_icon {
          width: 24px;
          top: 8px;
          left: 8px;
        }
      }
    }

    .certificate_logos {
      width: 464px;
      display: flex;
      justify-content: space-between;
      .certificate_img_1 {
        //width: 300px;
        filter: grayscale(100%);
      }
      .certificate_img_2 {
        width: 100px;
      }
    }
    .long_copy_right {
      display: none;
    }
    .short_copy_right {
      .copyrights_logo {
        width: 160px;
      }
    }
  }
  .outer_logo, .long_copy_right {
    display: none;
  }
  .first_router_link {
    margin-top: 30px;
  }
  @media (max-width: 1300px) {
    .logo_container {
      width: 17%;
    }
    .products_container {
      width: 24%;
    }
  }
  @media (max-width: 992px) {
    .logo_container.inner_logo {
      display: none;
    }
    .outer_logo {
      display: block;
      margin-top: min(5vh, 40px);
    }
    .products_container {
      width: 28%;
      margin-left: 5%;
    }
    .contacts_container {
      width: 29%;
    }
    .get_in_touch_container {
      width: 38%;
    }

    .footer_logo_container {
      flex-wrap: wrap;
      .social_icon_container {
        width: 100%;
        justify-content: center;
        margin-top: min(3vh, 24px);

        a:nth-child(1), a:nth-child(2), a:nth-child(3) {
          margin-right: 30px;
        }
      }
      .certificate_logos {
        width: 100%;
        justify-content: center;
        margin-top: min(3vh, 24px);

        .certificate_img_1 {
          margin-right: 60px;
        }
      }
      .short_copy_right {
        display: none;
      }
      .long_copy_right {
        display: block;
        width: 320px;
        margin: min(3vh, 24px) auto;
        .copyrights_logo {
          width: 320px;
        }
      }
    }
  }
  @media (max-width: 900px) {
    .contact_us_content {
      flex-wrap: wrap;
    }
    .products_container {
      width: 50%;
      margin-left: 10%;
    }
    .contacts_container {
      width: 30%;
    }
    .get_in_touch_container {
      width: 50%;
      margin-left: 10%;
      margin-top: min(5vh, 40px);
    }
  }
  @media (max-width: 768px) {
    .products_container {
      width: 50%;
      margin-left: 5%;
    }
    .contacts_container {
      width: 40%;
    }
    .get_in_touch_container {
      width: 50%;
      margin-left: 5%;
      margin-top: min(5vh, 40px);
    }
  }
  @media (max-width: 576px) {
    .outer_logo {
      width: 130px;
      margin-left: auto;
      margin-right: auto;
    }
    .products_container {
      width: 100%;
      margin-left: 0;
    }
    .contacts_container {
      width: 100%;
      margin-top: min(5vh, 40px);
    }
    .get_in_touch_container {
      width: 100%;
      margin-top: min(5vh, 40px);
      margin-left: 0;
    }
    .certificate_logos {
      flex-wrap: wrap;

      .certificate_img_1.margin_auto {
        margin-right: auto;
      }
      .certificate_img_2 {
        margin-top: min(5vh, 40px);
      }
      & > div {
        width: 100%;
        img {
          display: block;
          margin: auto;
        }
      }
    }
  }
  @media (max-width: 350px) {
    .footer_logo_container {
      .certificate_logos {
        .certificate_img_1 {
          width: 90%;
        }
      }
      .long_copy_right {
        width: 90%;
        .copyrights_logo {
          width: 90%;
        }
      }
    }
  }
</style>