<template>
  <AppHeader />
  <FormSubmitComponent v-if="showFormSubmit" />

  <router-view />

  <PrivacyRulesComponent v-if="privacyRules === null" @closePrivacyRules="closeRules" />
  <AppFooter />
</template>

<script>
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import PrivacyRulesComponent from "@/components/PrivacyRulesComponent";
import FormSubmitComponent from "@/components/FormSubmitComponent";

export default {
  name: "App",
  components: {
    AppFooter,
    AppHeader,
    PrivacyRulesComponent,
    FormSubmitComponent
  },
  data() {
    return {
      privacyRules: localStorage.getItem('MaugloPrivacyRules'),
      showFormSubmit: false,
    }
  },
  methods: {
    closeRules(result) {
      this.privacyRules = result
    }
  },
  mounted() {
    this.emitter.on("submit-form", () => {
      window.scrollTo(80,0)
      this.showFormSubmit = true
      setTimeout ( () => {
        this.showFormSubmit = false
      }, 5000)
    })
  }
}

</script>

<style lang="scss">

html {
  scroll-behavior: smooth;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
