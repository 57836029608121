export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
  "home_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart street lighting control"])},
  "home_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete smart street lighting control solution for smart cities. Simple to control and monitor all luminaires in one place on a map, decrease maintenance costs and increase savings."])},
  "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download .pdf"])},
  "lejupladet_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejuplādēt .pdf"])},
  "street_lighting_control_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street lighting control system"])},
  "home_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multifunctional and unique cloud-based smart street lighting control system with central management system (CMS) software. It is a perfect solution to manage large-scale outdoor lighting networks in order to control and monitor street lighting infrastructure while saving energy and reducing maintenance costs."])},
  "find_out_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out more"])},
  "home_luminaire_controller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminaire Controller"])},
  "home_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo smart street lighting Luminaire Controller is a wireless mesh-networking device that uses 868 MHz for communication with street lighting Segment Controller and other luminaire controllers. It is delivered in various configurations to meet the needs of your application."])},
  "home_segment_controller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment controller"])},
  "home_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo smart street lighting control system Segment Controller has 2-way communication with cloud-based Central Management System (CMS). Communication is possible via GSM or Ethernet. Further, it transmits tasks to Luminaire Controllers via radio frequency communication."])},
  "how_does_it_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work"])},
  "how_works_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo clients are using user-friendly CMS interface via web-browser to monitor / control / analyze / configure outdoor lighting networks."])},
  "how_works_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment controller stores all required configuration about energy-profiles / scenes / groups / lamps and can operate without internet and CMS."])},
  "how_works_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminaire controllers are configured to organize mesh-type network with self-healing features. Segment controller can reach farthest luminaire controller over big distances due to multi-hoping protocol."])},
  "home_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo clients are using user-friendly CMS interface via web-browser to monitor / control / analyze / configure outdoor lighting networks."])},
  "benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits"])},
  "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
  "remote_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote control"])},
  "savings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savings"])},
  "quick_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick response"])},
  "peace_of_mind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peace of mind"])},
  "products_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligent street lighting products"])},
  "products_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligent street lighting products from Mauglo for Smart City lighting and other sensor-based solutions."])},
  "smart_savings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart savings"])},
  "real_time_monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time monitoring and notifications, remote control."])},
  "save_up_to_90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save up to 90% on electricity bills and maintenance costs."])},
  "fix_the_problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix the problems before anyone notices there was one"])},
  "lighting_management_software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lighting Management Software"])},
  "products_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" It is a perfect solution to manage large-scale outdoor lighting networks in order to control and monitor street lighting infrastructure while saving energy and reducing maintenance costs."])},
  "products_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo smart street lighting Segment Controller implements gateway function between Luminaire Controllers and Central Management Software (CMS)."])},
  "products_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo smart street lighting Luminaire Controller is an easily installable wireless device which is carrying out the remote management and monitoring of a luminary in street lighting fixtures."])},
  "products_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An intelligent motion sensor street lighting control system that automatically activates when a car or pedestrian is noticed in the area."])},
  "products_paragraph_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo intelligent outdoor lighting solution for Smart City comes with an upgrade option to become a public streetlight WiFi hotspot. "])},
  "surge_protector_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surge Protector Device"])},
  "product_details_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street lighting control system"])},
  "product_details_subheading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Management System for street lighting control"])},
  "product_details_paragraph_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo has developed multifunctional and unique cloud-based smart street lighting control system with central management system (CMS) software."])},
  "product_details_paragraph_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a perfect solution to manage large-scale outdoor lighting networks in order to control and monitor street lighting infrastructure while saving energy and reducing maintenance costs. Lighting CMS is just the first step of more sophisticated Smart City platform development process."])},
  "product_details_benefits_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get control"])},
  "product_details_benefits_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save time"])},
  "product_details_benefits_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Money"])},
  "product_details_benefits_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["real-time monitoring and notifications, remote control."])},
  "product_details_benefits_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quicker potential problem discovery and problem-solving."])},
  "product_details_benefits_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lower maintenance costs & more intellectual energy consumption."])},
  "product_details_specification_heading_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even more automation and functionality"])},
  "product_details_specification_heading_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate with 3rd party systems"])},
  "product_details_paragraph_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our unique solution: smoothened dimming functionality for extra energy savings.\n(Up to <10% more energy saving)!"])},
  "product_details_paragraph_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo CMS is designed to support TALQ protocol specification, which ensures interoperability between Central Management Systems (CMS) and Outdoor Lighting Networks (OLN) from different vendors. The purpose of using TALQ specification in the creation of Mauglo CMS is to ensure the ability to control different Outdoor Lighting Networks from different vendors thus cities will be free to choose various TALQ compatible OLN automation suppliers while using Mauglo CMS."])},
  "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed"])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibility"])},
  "reliability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliability"])},
  "technical_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed is critical to businesses of all types, and is a key marker of competitive advantage."])},
  "technical_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many years of experience with enterprises & governments have hardened their shell."])},
  "technical_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional and global fail-over options, Hot and cold standby models, Rolling reboot capabilities"])},
  "technical_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s Microsoft. Large companies all over the World are choosing Azure because of being reliable."])},
  "product_details_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Lighting Segment Controller"])},
  "product_details_subheading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Lighting Segment Controller"])},
  "product_details_paragraph_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo smart street lighting Segment Controller implements gateway function between Mauglo Luminaire Controllers and Mauglo Central Management Software (CMS). The Mauglo Segment Controller communicate with CMS over wired Ethernet connection or GPRS/3G/4G GSM mobile operator network. It stores the configurations of the energy profiles and event reporting profiles ensuring stable operation in any circumstances. "])},
  "product_details_paragraph_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "product_details_benefits_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote control"])},
  "product_details_benefits_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliability"])},
  "product_details_benefits_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower costs"])},
  "product_details_benefits_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time monitoring and notifications, remote control."])},
  "product_details_benefits_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All configurations are stored locally without need of internet"])},
  "product_details_benefits_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control up to 200 street lights. Fewer data transferred."])},
  "product_details_paragraph_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It communicates with luminaires using RF (Radio frequency) data transmission technologies. Mauglo street lighting Segment Controller can set dimming profiles and receive energy measurements from up to 200 individual luminaires."])},
  "product_details_paragraph_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo CMS is designed to support TALQ protocol specification, which ensures interoperability between Central Management Systems (CMS) and Outdoor Lighting Networks (OLN) from different vendors. The purpose of using TALQ specification in the creation of Mauglo CMS is to ensure the ability to control different Outdoor Lighting Networks from different vendors thus cities will be free to choose various TALQ compatible OLN automation suppliers while using Mauglo CMS."])},
  "dali_communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DALI communication"])},
  "temperature_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature range"])},
  "rf_network_connector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RF network connector"])},
  "ethernet_port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethernet port"])},
  "gsm_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM connection"])},
  "technical_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AC 85-265 V / DC 19-72 V / PoE 18-48 V"])},
  "technical_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DALI master (10 mA supply current 12V)"])},
  "technical_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–40 °C … +75°C"])},
  "technical_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["868MHz, IEEE 802.15.4 standard"])},
  "technical_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RJ45 connector with Power over Ethernet (PoE)"])},
  "technical_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WxHxD : 82x90x23 mm (82x90x46 mm for 230v)"])},
  "technical_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPRS/3G/4G"])},
  "technical_8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60 g"])},
  "product_details_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street light luminaire controller"])},
  "product_details_subheading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Lighting Luminaire Controller"])},
  "product_details_paragraph_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo smart street lighting Luminaire Controller is an easily installable wireless device which is carrying out the remote management and monitoring of a luminary in street lighting fixtures. It creates a smart, energy-efficient and safe environment, and serves as a perfect platform for connecting various Smart City sensors, applications, and devices."])},
  "product_details_paragraph_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remotely programmable lighting energy profile schedules provide the users with reduction of energy consumption by up to 90% in a safe and comfortable manner."])},
  "product_details_benefits_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 90% Energy savings"])},
  "product_details_benefits_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 50% Maintenance cost Reduction"])},
  "product_details_benefits_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More safety"])},
  "product_details_benefits_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each luminaire’s remote control and dimming."])},
  "product_details_benefits_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time and more precise data"])},
  "product_details_benefits_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a quick notification regarding specific lamp if broken."])},
  "even_more_flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even more flexibility"])},
  "product_details_paragraph_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It communicates with street light segment controller using PLC (Power Line Communication) or RF (Radio frequency) data transmission technologies. Mauglo street lighting Luminaire Controller is using advanced mesh-network technology with self-healing feature for even more reliability."])},
  "technical_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical parameters"])},
  "download_full_specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download full specification"])},
  "power_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power supply"])},
  "supply_voltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply voltage"])},
  "operating_temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating temperature"])},
  "relay_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relay output"])},
  "type_of_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of load"])},
  "electrical_meter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrical meter"])},
  "rf_network_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RF network connection"])},
  "transceiver_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transceiver Class"])},
  "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "technical_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AC 50-60 Hz"])},
  "technical_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, optional (U, I, f, cosF, P, Q , S)"])},
  "technical_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["220-240 V ( Vmin 170 V; Vmax 265 V )"])},
  "technical_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["868MHz, IEEE 802.15.4 standard"])},
  "technical_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–40 °C … +75°C"])},
  "technical_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 (non-licensed b and with external antenna socket)"])},
  "technical_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2pcs (optional), 1 kVA (NO or NC) each relay output"])},
  "technical_8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LxWxH: 105x50x40 mm"])},
  "technical_9_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LED luminaire, LED/HID luminaire ballast, decorative lighting, incandescent bulb"])},
  "technical_10_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60 g"])},
  "product_details_heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion sensor street lighting"])},
  "product_details_subheading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion sensor street lighting control system with radar type sensor"])},
  "product_details_paragraph_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An intelligent motion sensor street lighting control system that automatically activates when a car or pedestrian is noticed in the area. If there is no activity in the area, the light is automatically adjusted to an optimized minimum light level. Expansion of functionality options using the multifunction radar technology."])},
  "product_details_paragraph_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "product_details_benefits_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significant energy savings"])},
  "product_details_benefits_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longer lifetime"])},
  "product_details_benefits_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less light pollution"])},
  "product_details_benefits_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["light is provided only where and when it is needed"])},
  "product_details_benefits_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even longer lifetime of LED lights"])},
  "product_details_benefits_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less light pollution and darker sky"])},
  "product_details_paragraph_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The motion sensor is installed in the lighting source and there is no need to drill holes on the pole for attachment (as a result of a loss of the stake and guarantee compliance with standard). Convenient fitting connector – Initially the option of installing luminaires with a Zhaga connector and mounting the sensor later."])},
  "product_details_specification_heading_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even more flexibility"])},
  "tracked_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracked data"])},
  "protection_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection level"])},
  "operationg_temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationg temperature"])},
  "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
  "capture_distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture distance"])},
  "dimming_configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimming configurations"])},
  "operating_angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating angle"])},
  "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
  "technical_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement, direction, distance, speed, presence"])},
  "technical_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP66"])},
  "technical_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–40 °C … +60°C"])},
  "technical_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhaga Book 18"])},
  "technical_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 40m"])},
  "technical_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed, distance, direction, delay"])},
  "technical_7_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["43˚ horizontally and 116˚ vertically"])},
  "technical_8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous, regardless of the external internet network"])},
  "product_details_heading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surge protector for street lighting"])},
  "product_details_subheading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surge protector for street lighting"])},
  "product_details_paragraph_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public lighting installations are exposed to the environment. Located where continuity of service is essential, it is crucial that these installations are protected against lightning and overvoltages and that can be achieved using a surge protector for street lighting."])},
  "product_details_paragraph_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 5 billion lightning strikes worldwide every year. 31% of electrical damage is caused directly or indirectly by the effect of lightning. Investing a small amount in protection can extend luminaire lifetime, improve public services and greatly reduce overall operating and infrastructure costs."])},
  "product_details_benefits_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longer lifetime"])},
  "product_details_benefits_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No down-time"])},
  "product_details_benefits_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower costs"])},
  "product_details_benefits_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximize the lifetime value of outdoor lighting applications"])},
  "product_details_benefits_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No down-time due to calamities (storms, lightning strikes, etc.)"])},
  "product_details_benefits_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid unnecessary maintenance costs"])},
  "product_details_benefits_7_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peace of mind"])},
  "product_details_benefits_8_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peace of mind on product performance"])},
  "product_details_paragraph_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It communicates with street light segment controller using PLC (Power Line Communication) or RF (Radio frequency) data transmission technologies. Mauglo street lighting Luminaire Controller is using advanced mesh-network technology with self-healing feature for even more reliability."])},
  "voltage_protection_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltage Protection level up (L-N)"])},
  "one_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I max"])},
  "voltage_protection_level_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltage Protection level up (L/N)-PE)"])},
  "one_nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nom"])},
  "uoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uoc"])},
  "art_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art. No."])},
  "technical_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ 1,5 kV"])},
  "technical_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 kA"])},
  "technical_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ 1,8 kV"])},
  "technical_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 kA"])},
  "technical_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10kV"])},
  "technical_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70020001"])},
  "product_details_heading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streetlight WiFi Hotspot"])},
  "product_details_subheading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streetlight WiFi Hotspot"])},
  "product_details_paragraph_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo intelligent outdoor lighting solution for Smart City comes with an upgrade option to become a public streetlight WiFi hotspot. "])},
  "product_details_paragraph_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo in partnership with one of the most advanced routers and wireless ISP systems manufacturer MikroTik is able to provide an easily installable wireless device which is carrying out the public internet hotspot function for the street lighting poles."])},
  "product_details_benefits_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully integrated"])},
  "product_details_benefits_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing infrastructure"])},
  "product_details_benefits_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizen friendly"])},
  "product_details_benefits_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "product_details_benefits_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "product_details_benefits_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "product_details_paragraph_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It communicates with street light segment controller using PLC (Power Line Communication) or RF (Radio frequency) data transmission technologies. Mauglo street lighting Luminaire Controller is using advanced mesh-network technology with self-healing feature for even more reliability."])},
  "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
  "wireless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless"])},
  "ram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAM"])},
  "antenna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antenna"])},
  "ethernet_ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethernet ports"])},
  "power_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power output"])},
  "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
  "power_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power input"])},
  "technical_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcomm Atheros AR9331 400 MHz"])},
  "technical_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR9331, 2GHz 802.11b/g/n built in"])},
  "technical_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["64 MB DDR2"])},
  "technical_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.3dBi built in"])},
  "technical_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–10 °C … +70°C"])},
  "technical_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["68x68x19mm"])},
  "technical_7_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x 10/100 Mbit/s Ethernet with Auto-MDI/X"])},
  "technical_8_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasive PoE output, up to 500mA on Ether 2"])},
  "technical_9_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16MB built in flash storage"])},
  "technical_10_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["802.3af/at PoE 10-57V, 24V 0.8A PSU included, supports powering from USB"])},
  "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessories"])},
  "accessories_sub_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-vandal antena"])},
  "accessories_sub_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External antena"])},
  "accessories_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resistant to vandalisms"])},
  "accessories_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides even better communication between devices"])},
  "what_cms_does": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What CMS does"])},
  "product_timer_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows to monitor and control all the luminaires remotely"])},
  "product_timer_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores and displays all the historical data in one place"])},
  "product_timer_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifies if problems occur"])},
  "timer_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does street light segment controller do"])},
  "product_timer_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides 2-way communication for street lights and Central Management System"])},
  "product_timer_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controls and monitors street lights and cabinet"])},
  "product_timer_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores configurations for stand-alone operating"])},
  "timer_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does street light luminaire controller do"])},
  "product_timer_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides 2-way communication for street lights driver and Segment Controller"])},
  "product_timer_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controls and monitors street light power and dimming level"])},
  "product_timer_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides energy measurements and delivers data to CMS"])},
  "timer_heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What it does"])},
  "product_timer_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detects motion, speed, and direction of object"])},
  "product_timer_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forwards information to next luminators with command to turn on (dim up) light"])},
  "product_timer_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less light pollution and darker sky"])},
  "timer_heading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does surge protector for street lights do"])},
  "product_timer_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The consequences of lightning damage become evident in partial or complete failure of the LED modules, destruction of the LED drivers,..."])},
  "product_timer_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...educed brightness or failure of electronic control systems. Even if the LED light is still operational, surges normally negatively affect its lifetime. Avoid it using Mauglo Surge Protector!"])},
  "timer_heading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does streetlight WiFi hotspot do"])},
  "product_timer_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public WiFi hotspot on street lighting luminaires provides internet connection for citizens and tourists which positively affects many factors ..."])},
  "product_timer_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... like free internet access helps many people to save on mobile internet data costs, public WiFi can positively and directly influence tourism and workplace productivity in the city as people become more mobile and it can work wonders in case of an emergency."])},
  "features_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street lighting control system features"])},
  "features_subheading_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminaires on map"])},
  "features_subheading_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy profiles"])},
  "features_subheading_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "features_subheading_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports and measurements"])},
  "features_description_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all existing luminaires and street lighting control boxes on map. Add new, view stats, edit existing luminaire’s parameters and their location."])},
  "features_description_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create different energy-profiles for each weekday, for different groups of luminaires at different times of the day. Up to 10 calendars and 30 different profiles."])},
  "features_description_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive real-time notifications if any problems occur.  Create a task right away from notification."])},
  "features_description_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Either on-regular basis prepared or on-demand requested reports are sent via e-mail or can be viewed in software."])},
  "features_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street lighting luminaire controller features"])},
  "features_subheading_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded and external options"])},
  "features_subheading_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long range wireless communication"])},
  "features_subheading_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrical parameters measurement"])},
  "features_subheading_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaptive lighting"])},
  "features_subheading_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software update Over-The-Air"])},
  "features_subheading_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 relay outputs"])},
  "features_description_1_2_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn’t affect the lamp design, embedded capabilities right from the lamp manufacturer."])},
  "features_description_1_2_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plug-and-play upgrade for lamps with compatible 7-pin NEMA socket (ANSI C136.41)."])},
  "features_bold_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded:"])},
  "features_bold_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEMA socket:"])},
  "features_description_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesh-network technology with RF 868MHz and antenna provides communication for up to 250m."])},
  "features_description_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesh-network technology with RF 868MHz and antenna provides communication for up to 250m."])},
  "features_description_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add adaptive lighting capabilities based on external sensors. Multifunctional communication options from up to 2 different sensors."])},
  "features_description_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing the need for cables, computers or third-party programs, FOTA updates devices to fix bugs or add features to the device."])},
  "features_description_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mauglo Luminaire Controller has 2 relay outputs so you can control decorative lighting separately."])},
  "features_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street lighting segment controller features"])},
  "features_subheading_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPRS/3G/4G GSM and Ethernet"])},
  "features_subheading_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy profiles"])},
  "features_subheading_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stand-alone"])},
  "features_subheading_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware upgrade Over-The-Air"])},
  "features_description_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3G/4G GSM and/or Ethernet can be used for communication with the central server. Both available as options to optimise your costs."])},
  "features_description_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible energy profiles based on astronomical/photocell/sensor data. Up to 10 calendars and 30 different profiles."])},
  "features_description_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Built-in astronomical calendar + locally stored configurations allow operating in stand-alone mode (without need of internet)"])},
  "features_description_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing the need for cables, computers or third-party programs, FOTA updates devices to fix bugs or add features to the device."])},
  "related_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related street lighting products:"])},
  "related_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control, monitor, gather and store."])},
  "related_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local configs & gateway for CMS & lights"])},
  "related_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each luminaire’s remote monitoring and control"])},
  "related_description_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lighting on demand, even more savings"])},
  "related_description_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection against overvoltages & lightning"])},
  "related_description_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides accessible internet on go"])},
  "management_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management System"])},
  "projects_main_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart street lighting projects"])},
  "projects_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are expanding very quickly and adding new smart street lighting projects from all over the world. Here are just a few of our projects delivered."])},
  "projects_subheading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maras park, Latvia"])},
  "projects_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.2017 – In December Mauglo launched new smart street lighting project in Latvia city of Riga."])},
  "projects_subheading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kappara, Malta"])},
  "projects_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.2017 – In December Mauglo launched new smart street lighting project in Kappara city of Malta."])},
  "projects_subheading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel Aviv, Israel"])},
  "projects_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our first smart street lighting project in Isreal."])},
  "projects_subheading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baldone, Latvia"])},
  "projects_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In March Mauglo launched new smart street lighting system in beautiful city Baldone."])},
  "date_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2017 / 09"])},
  "date_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2017 / 09"])},
  "date_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2017 / 05"])},
  "date_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2017 / 05"])},
  "our_mission_and_beliefs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission and beliefs"])},
  "about_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Mauglo, our mission is to contribute to the creation of self-sustainable and smart cities. We fulfil this mission by offering best-in-class intelligent lighting solutions that allow cutting down the energy and maintenance costs and creating a comfortable and safe environment for citizens."])},
  "about_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We strive to achieve the lighting experience that goes beyond lighting. That includes different sensor data collection and intelligent management which leads to the good of cities and citizens. Everything we do is about data-driven smart decisions."])},
  "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality"])},
  "superior_customer_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superior customer support"])},
  "individual_approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual approach"])},
  "quality_benefits_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each luminaire’s remote control and dimming."])},
  "quality_benefits_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time and more precise data"])},
  "quality_benefits_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a quick notification regarding specific lamp if broken."])},
  "about_subheading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo is a global industry innovator in street lighting control solutions. Our team consists of highly competent street lighting industry experts and researchers with doctoral degrees in the field of electro-technology, electricity, electronics and IT industry."])},
  "about_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mauglo team members are regular guests at smart city conferences, seminars and have won industry awards from “Latvian Chamber of Commerce and Industry”. Mauglo has developed a multifunctional and unique cloud-based Street Lighting Central Management System (CMS)."])},
  "about_subheading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Lighting CMS is just the first step towards developing a much more advanced multifunctional smart city platform. Focused on quality, reliability, and innovation, Mauglo offers a complete solution package of controls and sensors, enabling luminaire manufacturers, utilities, and municipalities to make better use of resources, save operating costs and protect the environment by managing energy better"])},
  "about_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We see street lights as one of the most valuable public assets that have a powerful role to play in making cities energy-efficient, resilient and future-ready."])},
  "about_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo helps cities and infrastructure operators capitalize on the Internet of Things, avoid provider lock-in, and make the best use of what smart lighting can offer."])},
  "about_paragraph_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our products create an independent open network that allows integrating a multitude of third-party applications and constitutes a reliable, future-proof base for a Smart City."])},
  "about_subheading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo helps cities and infrastructure operators capitalise on the Internet of Things, avoid provider lock-in, and make the best use of what smart lighting can offer."])},
  "project_short_date_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 / 03"])},
  "project_short_date_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 / 04"])},
  "project_short_date_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 / 06"])},
  "project_short_date_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 / 07"])},
  "project_short_date_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 / 09"])},
  "project_short_date_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 / 10"])},
  "project_short_date_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 / 12"])},
  "project_short_date_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 / 01"])},
  "project_short_date_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 / 03"])},
  "project_short_date_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 / 04"])},
  "project_short_date_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 / 06"])},
  "project_short_date_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 / 07"])},
  "project_short_date_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 / 09"])},
  "project_short_date_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 / 12"])},
  "project_short_date_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 / 03"])},
  "project_short_date_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 / 06"])},
  "project_short_date_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 / 09"])},
  "project_short_date_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 / 10"])},
  "project_short_date_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2024 / 02"])},
  "project_date_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.03.2021"])},
  "project_date_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.04.2021"])},
  "project_date_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.06.2021"])},
  "project_date_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.07.2021"])},
  "project_date_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.09.2021"])},
  "project_date_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.10.2021"])},
  "project_date_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.12.2021"])},
  "project_date_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.01.2022"])},
  "project_date_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.03.2022"])},
  "project_date_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.04.2022"])},
  "project_date_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.06.2022"])},
  "project_date_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.07.2022"])},
  "project_date_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27.09.2022"])},
  "project_date_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16.12.2022"])},
  "project_date_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.03.2023"])},
  "project_date_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.06.2023"])},
  "project_date_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.09.2023"])},
  "project_date_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31.10.2023"])},
  "project_date_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.02.2024"])},
  "period_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For period 09.01.2021. – 09.04.2021"])},
  "period_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For period until 26.07.2021"])},
  "period_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Period 01.04.2021. – 30.06.2021"])},
  "period_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For period until 26.09.2021"])},
  "period_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Period 01.07.2021-30.09.2021"])},
  "period_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par paveikto periodā: 01.10.2021. – 31.12.2021."])},
  "period_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par paveikto periodā: 01.01.2022. – 31.03.2022."])},
  "period_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par paveikto periodā: 01.04.2022. – 30.06.2022."])},
  "period_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai"])},
  "period_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalība mazo un vidējo uzņēmumu  inovatīvās uzņēmējdarbības attīstības programma"])},
  "project_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu Sensoru un vadības Algoritmu izstrāde Viedo pilsēttehnoloģiju ielu Apgaismojuma Sistēmām (SAVAS)"])},
  "project_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu Sensoru un vadības Algoritmu izstrāde Viedo pilsēttehnoloģiju ielu Apgaismojuma Sistēmām (SAVAS)"])},
  "project_title_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu Sensoru un vadības Algoritmu izstrāde Viedo pilsēttehnoloģiju ielu Apgaismojuma Sistēmām (SAVAS)"])},
  "project_title_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu Sensoru un vadības Algoritmu izstrāde Viedo pilsēttehnoloģiju ielu Apgaismojuma Sistēmām (SAVAS)"])},
  "project_title_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu Sensoru un vadības Algoritmu izstrāde Viedo pilsēttehnoloģiju ielu Apgaismojuma Sistēmām (SAVAS)"])},
  "project_title_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu Sensoru un vadības Algoritmu izstrāde Viedo pilsēttehnoloģiju ielu Apgaismojuma Sistēmām (SAVAS)"])},
  "project_title_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_title_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_nr_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_nr_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/18/A/115"])},
  "project_nr_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_nr_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/18/A/115"])},
  "project_nr_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_nr_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/18/A/115"])},
  "project_nr_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_nr_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/18/A/115"])},
  "project_nr_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "project_nr_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/18/A/115"])},
  "project_nr_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/20/A/028"])},
  "project_nr_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/18/A/115"])},
  "project_nr_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/20/A/028"])},
  "project_nr_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/20/A/028"])},
  "project_nr_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/20/A/028"])},
  "project_nr_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/20/A/028"])},
  "project_nr_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/20/A/028"])},
  "project_nr_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1.1.1/20/A/028"])},
  "project_nr_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.1-1-L-2024/105"])},
  "project_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” un Centrālā finanšu un līgumu aģentūra 26.03.2021. ir parakstījusi līgumu par Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai”, un jau ir uzsākusi Projekta īstenošanas darbības. Dotajā brīdī ir uzsāktas darbības, kas ir saistītas ar jauno kustības sensoru (datorredzes sensora un mikro viļņu sensora) arhitektūras, tehnisko parametru un funkcionalitātes prasību definēšanu. Projekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata periodā turpināts darbs pie 4.2. “Radara kustibas sensora testa datu analize” – veikti kustību sensora eksperimenti reālā vidē un analizēti iegūtie dati. Tāpat turpinās darbi pie šādām aktivitātēm: 1)4.2. “Radara kustibas sensora testa datu analize” – veikta veiktspējas (KPI) parametru analīze 2)4.4. “Kustibas sensoru datu analize – jaunas funkcionalitates parbaude un optimizacija” – veikti kustību sensora pirmie kontrolētie eksperimenti 3)4.6. “Dinamiskas ME apgaismojuma klases noteikšanas algortima izveide un testešana” – veikta standarta izpēte. "])},
  "project_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. Dotajā brīdī tiek turpinātas darbības, kas ir saistītas ar jauno kustības sensoru (mikro viļņu sensora) arhitektūras, tehnisko parametru un funkcionalitātes prasību definēšanu. Vienlaicīgi ir uzsāktas darbības, ko veic līgumpētījuma veicējs un kas ir saistītas ar datorredzes (CV) sensora prasību definēšanu. Projekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabeigts darbs pie aktivitātes Nr. 2.5. “Sensoru testēšana un kontrolleru pielāgošana darbam LoRa infrastruktūrā” – darbības ietvaros veikta sensoru prototipu (vairāki vienlaicīgi), gaismekļu un segmenta kontrolleru pārbaude pilnas funkcionalitātes darbībai LoRa tīklā. Sadarbībā ar projekta partneriem SIA TET un RTU uzsākts darbs pie atskaišu sagatavošanas. Turpināts darbs pie aktivitātes Nr. 3.1. “Lielas pilsētas tipa pilotvieta (piem.Rīga)” – veikta saziņa ar pašvaldības pārstāvjiem, lai nodrošinātu gaismekļu aprīkošanu nepieciešamo mērījumu veikšanai. "])},
  "project_description_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. Dotajā brīdī tiek turpinātas darbības, kas ir saistītas ar jauno kustības sensoru (datorredzes sensora un mikro viļņu sensora) arhitektūras, tehnisko parametru un funkcionalitātes prasību definēšanu. Darbības, kas attiecās uz mikroviļņu sensoru veic Projekta iesniedzējs, savukārt darbības, kas attiecās uz datorredzes sensoru veic līgumpētījuma veicējs – Rīgas Tehniskā universitāte. Projekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turpināts darbs aktivitātes Nr.3.1. “Lielas pilsētas tipa pilotvieta (piem.Rīga)” ietvaros. Rīgā turpināti testi ar radara sensoriem, kas integrēti gaismekļa korpusā un turpināti testi ar PIR tuvas un tālas darbības sensoriem. Tāpat veikts darbs aktivitāšu 4.2.“Radara kustības sensora testa datu analīze”   un 4.4. “Kustības sensoru datu analīze – jaunās funkcionalitātes pārbaude un optimizācija ietvaros” ietvaros – ir optimizēts radara sensora kods izmantojot pirmos testa rezultātus, veikti eksperimenti precizitātes un datu uzticamības uzlabošanai, pārbaudīta kustību sensoru funkcionalitāte laboratorijas eksperimentālajos apstākļos. Darbības tiek turpinātas arī nākamajos posmos."])},
  "project_description_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. Dotajā brīdī tiek turpinātas darbības, kas ir saistītas ar jauno kustības sensoru (datorredzes sensora un mikro viļņu sensora) arhitektūras, tehnisko parametru un funkcionalitātes prasību definēšanu. Paredzams, ka aktivitātes tiks pabeigtas ar 28.02.2022. Aktivitātē izmantotie rezultāti tiks izmantoti dziļo neirona tīkla apmācības sistēmas izveidei. Projekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadarbībā ar projekta vadošo partneri Rīgas Tehnisko universitāti sagatavota apkopota atskaite par darbību Nr.1 “Sistēmas arhitektūras izveide un eksperimentu plānošana”, kā arī periodā iesniegta projekta vidusposma zinātniskā atskaite. Lai noslēgtu pētījumu, turpināts darbs aktivitātes Nr.3.1. “Lielas pilsētas tipa pilotvieta (piem.Rīga)” ietvaros, uzsākts darbs pie šīs aktivitātes noslēguma atskaites. Kopumā nodrošināta IoT tīkla infrastruktūras sagatavošana un pilotvietai nepieciešamais aprīkojums. Eksperimentu un datu mērījumus plānots uzsākt divās ielās. Savukārt, 4.2. “Radara kustības sensora testa datu analīze” darbības ietvaros veikti nepieciešamie darbi, lai pabeigtu radara sensora koda ar integrētu SAVAS dinamisko vadības algoritmu izstrādi."])},
  "project_description_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. Dotajā brīdī ir noslēgtas aktivitātes, kas attiecās uz Datorredzes (CV) sensora prasību definēšanu un Eksperimentālā plāna un metožu izstrādi, kā arī tuvākajā laikā tiks noslēgta aktivitāte, kas attiecās uz Jaunā mikro viļņu sensora prasību definēšanu.Paralēli minētajam ir uzsāktas darbības, kas attiecās uz dziļo neirona tīkla apmācībām nepieciešamo datu iegūšana un modeļa izstrādi, kā arī abu sensoru testa maketa programmatūras izveides. Projekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektā saņemts pozitīvs zinātniskās vidusposma atskaites ekspertu vērtējums. Noslēgta darbība Nr.3.1. “Lielas pilsētas tipa pilotvieta (piem.Rīga)” – sagatavota atskaite par sasniegtajiem rezultātiem darbības ietvaros. Darbības Nr. 4.2. “Radara kustības sensora testa datu analīze” ietvaros pabeigts radara sensora kods ar integrētu SAVAS dinamisko vadības algoritmu, optimizēts radara sensorelementa leņķa novietojums attiecībā pret Zhaga korpusu. Savukārt, darbības Nr. 4.4 “Kustības sensoru datu analīze – jaunās funkcionalitātes pārbaude un optimizācija” ietvaros apkopoti dati par LED gaismekļa sistēmas mērījumiem, turpināts darbs pie programmas koda un iestatījumu tālākas optimizācijas."])},
  "project_description_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. Dotajā brīdī ir noslēgtas aktivitātes, kas attiecās uz Datorredzes (CV) sensora prasību definēšanu, Jaunā mikro viļņu sensora prasību definēšanu un Eksperimentālā plāna un metožu izstrādi Paralēli minētajam tiek turpinātas darbības, kas attiecās uz dziļo neirona tīkla apmācībām nepieciešamo datu iegūšana un modeļa izstrādi, kā arī abu sensoru testa maketa programmatūras izveides. Projekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30.06.2022. noslēgts projekta aktivitāšu īstenošanas aktīvais posms. Sadarbībā ar projekta vadošo partneri Rīgas Tehnisko universitāti pieņemts lēmums turpināt atsevišķu pētījumu veikšanu/ datu analizēšanu saistībā ar uzstādītajiem prototipiem līdz 15.12.2022. Iegūstot šos datus būs iespējams apzināt sistēmas priekšrocības un trūkumus, sagatavot kvalitatīvākus noslēguma ziņojumus un rekomendācijas."])},
  "project_description_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. Projekta ietvaros tiek turpināts darbs pie tiešsaistes video plūsmas iegūšanas un datu glabāšanas sistēmas arhitektūras/projektējuma izstrādes. Veikta YOLO algoritma testēšana dažādos apstākļos un pierādīta tā spēja atpazīt satiksmes dalībniekus satiksmes plūsmā. Ir izveidota plaša datubāze neironu tīklu marķēšanai. Ir veikti testi un pierādīta izvēlēto algoritmu un sistēmas pareizība. Ir izveidota maza mēroga sistēmas testēšanas modeļi. Turpinās darbs pie dziļo neironu tīkla apmācības modeļu izstrādes, ir veikti sistēmas atpazīstamības testi dažādos vides apstākļos. Tiek turpināta MV sensora testa programmatūras izveide, kas ietver objekta atrašanas funkcionalitāti. Notiek darbs pie sensora aparatūras un sistēmas programmatūras izstrādes un pilnveidošanas. Ir izveidota sensora struktūra, definēti tā komponenti un galvenās funkcijas. Projekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. \n\nTurpinās darbs  pie MV sensora testa maketa programmatūras izveides (sākotnējās arhitektūras/projektējuma izstrāde). Darbs pie detektēšanas un automātiskās kalibrācijas algoritmu adaptācijas iegultajā MW sensora modulī. Notiek darbs pie automātiskas jūtības kalibrācijas algoritma realizācijas, t.sk. jūtības kalibrācijas algoritma pārbaude dažādos novietojumos (leņķis, attālums) pret detektējamo virsmu. Tie veikta signālapstrādes algoritmu veikstpējas analīze, kā arī signālapstrādes algoritmu izstrāde detektēto objektu klasifikācijai un aprakstošo klasifikatoru piešķiršanai.\n\nNotiek testa datu analīze un sensora algoritmu adaptācija izejot no testa datiem.\n\nProjekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. \n\nTurpinās darbs  pie MV sensora testa maketa programmatūras izveides. Tiek izstrādāti algoritmi signālapstrādes detektēto objektu klasifikācijai un notiek darbs pie aprakstošo klasifikatoru piešķiršanas. Tiek veikta  signālapstrādes algoritmu veiktspējas, kā arī objekta aprakstošo klasifikatoru analīze. Notiek iegūto sensoru datu ieguve un savākto testa datu apstrāde un analīze.\n\nNotiek darbs pie MV sensoru prototipa un signālapstrādes algoritmu labojumiem un sensoru kalibrācijas, lai uzlabotu objektu detektēšanas precizitāti.  \n\nProjekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. \n\nIr pabeigta MV sensora maketa izstrāde un ir uzsākts darbs pie sensora moduļu  un datu savācēju ierīču sagatavošanas un pārbaudes pirms prototipu validācijas uzsākšanas pilotvietās.\n\nTuvojas noslēgumam dziļo neironu tīkla modeļa apmācība, testēšana un uzlabošana, ko veic Rīgas Tehniskā universitāte līgumpētījuma ietvaros.\n\nAtlikušajā projekta īstenošanas laikā tiks veikta abu sensoru prototipu validācija mākslīgā vidē, kā arī iegūto datu analīze un gala novērtējums.\n\nProjekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 30.09.2023."])},
  "project_description_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” turpina Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. \n\nTurpinās darbs pie prototipu validācijas mākslīgā vidē un prototipa sagatavošana pilot vietām, kas ietver\nmoduļu barošanas, komunikācijas, mikroviļņu sensora uztvērēja pārbaudes un programmatūras izstrādes datu savākšanai un pārsūtīšanai uz savācēj iekārtu; signāl apstrādes algoritmu uzlabojumu un optimizācija veikšanu, klasifikatoru korekcijas, un enerģijas patēriņa optimizācijas. \nVeikta prototipa sagatavošana mērījumu veikšanai, tā mehāniskās noturības pārbaude, un konstrukcijas uzlabojumu veikšana.\n\nTurpinās pilotvietu analīze un apsekošana,  t.sk. ielu profila analīze un izvēle. Turpinās kontrolētu testu veikšana pilotvidē , datu savākšana un sagatavošana apstrādei.\n\nAtlikušajā projekta īstenošanas laikā tiks paveikta abu sensoru prototipu validācija mākslīgā vidē, kā arī iegūto datu analīze un gala novērtējums.\n\nProjekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. Projektu plānots īstenot līdz 31.10. 2023."])},
  "project_description_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” ir noslēdzis Projekta Nr. 1.1.1.1/20/A/028 “Jaunu sensoru izstrāde un to pielietojumu izpēte satiksmes un gājēju plūsmas datu iegūšanai viedo pilsētu apgaismojuma un citu pilsētvides plānošanas risinājumu uzlabošanai” īstenošanu. \n\nProjekta noslēguma posmā tika pabeigts darbs pie prototipu validācijas mākslīgā vidē, veikta\nMW sensora prototipa signālu apstrādes algoritmu optimizācija, koda izmēra samazināšana, ātrdarbības uzlabošana un enerģijas patēriņa optimizācija.  Tāpat tika veikti kontrolēti prototipa testi reālā vidē un testu datu apstrāde, detektēšanas precizitātes noteikšana un uzlabošana. Tika pabeigta prototipa datu savācēj iekārtas darbības stabilitātes pārbaude.\n\nTāpat tika pabeigts darbs pie datu savākšanas procesa, kontrolēto testu datu apstrādes un detektēšanas precizitātes noteikšanas un uzlabošanas; datu analīzes veikšanas un sagatavots apkopojums par MW sensoru.\n\nProjekta kopējās izmaksas: 658 936,18 EUR no tām plānotais ERAF atbalsts 468 254,13 EUR. "])},
  "project_description_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA “Mauglo” ir noslēdzis līgumu  Nr.17.1-1-L-2024/105  ar Latvijas Investīcijas un attīstības aģentūru (LIAA) par “eksporta atbalsta nodrošināšanu” un dalību mazo un vidējo uzņēmumu (MVU) inovatīvās uzņēmējdarbības attīstības programmā. \n\nLīguma mērķis ir sniegt atbalstu Atbalsta saņēmējam saskaņā ar  Eiropas Savienības Eiropas Reģionālās attīstības fonda projektu  „MVU inovatīvas uzņēmējdarbības attīstība”, projekta  identifikācijas numurs 1.2.3.1/1/23/I/001 (turpmāk - Projekts)  eksporta atbalsta nodrošināšanai, kas apstiprināts ar Centrālās finanšu un līguma aģentūras 2023. gada 15.septembra lēmumu Nr.  39-2-10/5828 un īstenots saskaņā ar 2023.gada 26.septembra  noslēgto vienošanos par projekta īstenošanu Eiropas Savienības kohēzijas politikas programmas 2021.-2027. gadam 1.2.3.  specifiskā atbalsta mērķa “Veicināt ilgtspējīgu izaugsmi,  konkurētspēju un darba vietu radīšanu MVU, tostarp ar  produktīvām investīcijām” 1.2.3.1. pasākuma “Atbalsts MVU  inovatīvas uzņēmējdarbības attīstībai” (turpmāk – Vienošanās par  projekta īstenošanu) ietvaros."])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "project_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekta nosaukums"])},
  "agreement_about_project_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vienošanās par projekta īstenošanu numurs"])},
  "such_activities_have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiktās aktivitātes projektā:"])},
  "certificates_subheading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo quality & safety certificates"])},
  "certificates_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe equipment and systems, adequate processes, and properly trained employees are vital for manufacturers and operators in the energy and power market. "])},
  "certificates_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Mauglo, we make sure our products comply with international standards, national deviations, and end-user specifications to maintain a high level of safety and reliability."])},
  "technical_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN 61347-2-11:2001"])},
  "technical_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN 61347-1:2005"])},
  "technical_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cert. No. 2193259.01"])},
  "technical_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RoHS 2011/65/EU"])},
  "technical_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cert. No. 7299510B"])},
  "technical_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETSI EN 300 220-1 v2.4.1 (2012-05)"])},
  "technical_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETSI EN 300 220-2 v2.4.1 (2012-05)"])},
  "technical_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report No. 49196RRF.001"])},
  "technical_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN 301 489-1 v1.9.2 (01)"])},
  "technical_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN 301 489-17 v2.2.1 (12)"])},
  "technical_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN 55015 (2013)"])},
  "technical_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN 61547 (2009)"])},
  "technical_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report No.49196REM.001"])},
  "certificates_subheading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauglo helps cities and infrastructure operators capitalise on the Internet of Things, avoid provider lock-in, and make the best use of what smart lighting can offer."])},
  "control_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control Panel"])},
  "segment_controller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment controller"])},
  "luminaire_controller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminaire Controller"])},
  "motion_sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Sensor"])},
  "surge_protector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surge Protector"])},
  "wifi_hotspot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WiFi Hotspot"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA Mauglo"])},
  "reg_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reg.Nr"])},
  "company_reg_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40103372620"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "company_address_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarkandaugavas street 19A-31,"])},
  "company_address_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riga, LV-1005, Latvia"])},
  "mob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mob"])},
  "company_mob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+371-26371717"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "form_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent to having this website store my submitted information so they can respond to my inquiry. For detailed information see our "])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "form_was_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form was submitted"])},
  "privacy_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We inform you that cookies are used on the web page. By continuing to use this web page, you agree that we will accumulate and use cookies on your device. For more information, see our"])},
  "accept_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept and continue"])},
  "privacy_subheading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use and data privacy policy"])},
  "privacy_subheading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Copyright"])},
  "privacy_subheading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Customer data and their use"])},
  "privacy_subheading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Who is using the data and how they are protected?"])},
  "privacy_subheading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. How long will the data be stored for and when they are deleted?"])},
  "privacy_subheading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Using cookies/use of google analytics"])},
  "privacy_subheading_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Other terms"])},
  "privacy_paragraph_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms of Use and Data Privacy Policy (hereinafter – Terms) lay down the procedure for data processing by SIA “MAUGLO”, registration number 40103372620, address Sarkandaugavas street 19A-31, Riga, LV-1005, Latvia, hereinafter – MAUGLO, is used."])},
  "privacy_paragraph_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms apply to natural persons, hereinafter – Customer, who uses MAUGLO web page or used it before the entry into force of these Terms. The Terms relating to personal data of the Customers are applied until they are deleted."])},
  "privacy_paragraph_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any publications, including but not limited to any patent, copyright, registered design, trade mark or other industrial or intellectual property right in respect of the Products, published on the web site, are the property of MAUGLO, and the materials are protected by the Copyright Law. Any reproduction, distribution, copying, storing or any other use of the materials is prohibited without a written authorization of MAUGLO."])},
  "privacy_paragraph_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer data provide on form “Contact us” becomes available to MAUGLO, however, MAUGLO only has access to the data necessary for its business, protection and development purposes, as well as for communication with the Customer."])},
  "privacy_subsubheading_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.1. Customer identifying data"])},
  "privacy_subsubheading_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.2. Customer’s contact information"])},
  "privacy_subsubheading_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.3. Internet protocol (IP) address"])},
  "privacy_paragraph_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On form “Contact us” Customer can provide his name, e-mail and message, which are used for purpose of communication with the Customer."])},
  "privacy_paragraph_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Customer is contacted by e-mail provided on “Contact us” form."])},
  "privacy_paragraph_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAUGLO stores information about the Customer’s IP address, date and time of visit. MAUGLO needs these data for safety purposes, for example, detection and prevention of illegal activities, etc."])},
  "privacy_paragraph_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data of the Customers are exclusively used for MAUGLO business and development purposes, as well as for the purpose of communication with the Customers. Only MAUGLO employers and relevant outsourcing service providers involved directly in technical maintenance, improvement and enhancement of MAUGLO products have access to these data. MAUGLO takes full responsibility for data confidentiality and service providers may only process the data in strict compliance of MAUGLO instructions."])},
  "privacy_paragraph_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAUGLO implements all reasonable technical and organisational measures to protect personal data of the Customers against unauthorized access, accidental loss, disclosure or destruction."])},
  "privacy_paragraph_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAUGLO stores all personal data provided by the Customer on secured servers protected by passwords, firewalls, etc. appropriate to modern technologies."])},
  "privacy_paragraph_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Customer – registered user – may delete the account at any time by submitting a written request to MAUGLO to delete the account."])},
  "privacy_paragraph_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAUGLO may delete the Customer information if it is no longer required to satisfy need requested on form “Contact us”."])},
  "privacy_paragraph_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyway, once the Customer data have been deleted, they are irretrievably deleted from all systems within reasonable period of time, taking into account the possibility of human errors (intentional or unintentional), and safeguarding the business interests of MAUGLO. Data may only be deleted to the extent permitted by the effective laws of the Republic of Latvia."])},
  "privacy_paragraph_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAUGLO use other third-party data analytics service providers in order to improve the Services Management Events may use tools from third-party service providers, such as Google Analytics, a web analytics service provided by Google, Inc. (hereinafter – Google”)."])},
  "privacy_paragraph_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Analytics uses “cookies”. The information generated by the cookie about your use of the Websites is usually transmitted to a Google server in the USA and stored there."])},
  "privacy_paragraph_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third parties, including Google, use stored cookies to serve ads based on previous visits by a User to this site. Google may provide a service associated with website activity and internet usage in general and may transfer cookie information to third parties as required by law or as far as third parties process this information on behalf of Google."])},
  "privacy_paragraph_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The IP address provided by Google Analytics as part of Google Analytics will not be merged with other Google data. You can prevent the storage of cookies. In addition, you may prevent the collection by Google of the data generated by the cookie and related to your use of the website (including your IP address) and the processing of this data by Google by using the available browser plug-in: https://tools.google.com/dlpage/gaoptout?hl=en."])},
  "privacy_paragraph_1_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Customer has the right to request MAUGLO to provide information about the Customer’s personal data processing, by submitting an official application to MAUGLO in person and presenting a personal identification document or sending an electronically signed document. MAUGLO shall provide a reply to the Customer’s application under the procedure and within the time period laid down in the legislation."])},
  "privacy_paragraph_2_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Customer is entitled to request to update or rectify, delete data or restrict data processing. The Customer has the right to object to its data processing, as well as it has the right to data portability. MAUGLO shall examine each request of the Customer and exercise the Customer’s right in accordance with the requirements of the legislation."])},
  "privacy_paragraph_3_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about its personal data processing or Member, please do not hesitate to contact MAUGLO."])},
  "privacy_paragraph_4_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any uncertainties, suggestions or questions, please contact MAUGLO by telephone +371 26371717 or e-mail "])}
}