<template>
  <div class="navbar" :class="{ 'navbar--hidden': !showNavbar }">
    <router-link class="mauglo_logo" to="/" @click="resetHeader">
      <img src="@/assets/mauglo-logo.png" alt="">
    </router-link>
    <div>
      <router-link class="router_link extra_home" :class="[focusElem != null ? focusOut : '']" to="/" @click="linkClick">{{ $t('home') }}</router-link>
      <router-link class="router_link" :class="[focusElem === 'Products' ? dotElem : (focusElem != null ? focusOut : '')]"  to="/products" @click="linkClick">{{ $t('products') }}</router-link>
      <router-link class="router_link" :class="[focusElem === 'Projects' ? dotElem : (focusElem != null ? focusOut : '')]" to="/projects" @click="linkClick">{{ $t('projects') }}</router-link>
      <router-link class="router_link" :class="[focusElem === 'About us' ? dotElem : (focusElem != null ? focusOut : '')]" to="/about" @click="linkClick">{{ $t('about') }}</router-link>
      <router-link class="router_link" :class="[focusElem === 'News' ? dotElem : (focusElem != null ? focusOut : '')]" to="/news" @click="linkClick">{{ $t('news') }}</router-link>
      <router-link class="router_link" :class="[focusElem === 'Certificates' ? dotElem : (focusElem != null ? focusOut : '')]" to="/certificates" @click="linkClick">{{ $t('certificates') }}</router-link>
      <router-link class="router_link" :class="[focusElem === 'Contacts' ? dotElem : (focusElem != null ? focusOut : '')]" to="/contacts" @click="linkClick">{{ $t('contacts') }}</router-link>
    </div>

    <a class="login_button single_button" href="https://cms.citintelly.com/login" target="_blank">
      {{ $t('login') }}
    </a>

    <div class="right_side">
      <a class="login_button" href="https://cms.citintelly.com/login" target="_blank">
        {{ $t('login') }}
      </a>
      <div class="menu_button" @click="changeMenuVisibility">
        <img src="@/assets/menu_img.png">
      </div>
    </div>

    <div class="full_screen_navigation" v-if="showMenuSelect === true">
      <div class="header">
        <div>
          <router-link class="logo_container" to="/" @click="changeNavBar('home')">
            <img src="@/assets/mauglo-logo-white.png">
          </router-link>
        </div>
        <div class="close_button" @click="changeMenuVisibility">
          <img src="@/assets/close_button.png">
        </div>
      </div>
      <div class="nav_links">
        <router-link class="menu_link" :class="[focusElem != null ? focusOut : '']" to="/" @click="linkClick">{{ $t('home') }}</router-link>
        <router-link class="menu_link" :class="[focusElem !== 'Products' && focusElem != null ? focusOut : '']" to="/products" @click="linkClick">{{ $t('products') }}</router-link>
        <router-link class="menu_link" :class="[focusElem !== 'Projects' && focusElem != null ? focusOut : '']" to="/projects" @click="linkClick">{{ $t('projects') }}</router-link>
        <router-link class="menu_link" :class="[focusElem !== 'About us' && focusElem != null ? focusOut : '']" to="/about" @click="linkClick">{{ $t('about') }}</router-link>
        <router-link class="menu_link" :class="[focusElem !== 'News' && focusElem != null ? focusOut : '']" to="/news" @click="linkClick">{{ $t('news') }}</router-link>
        <router-link class="menu_link" :class="[focusElem !== 'Certificates' && focusElem != null ? focusOut : '']" to="/certificates" @click="linkClick">{{ $t('certificates') }}</router-link>
        <router-link class="menu_link" :class="[focusElem !== 'Contacts' && focusElem != null ? focusOut : '']" to="/contacts" @click="linkClick">{{ $t('contacts') }}</router-link>
      </div>
      <hr>
      <a class="white_login_button" href="https://cms.citintelly.com/login" target="_blank" @click="changeMenuVisibility">
        {{ $t('login') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  data () {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      dotElem: "dot_below_elem",
      focusOut: "focused_out",
      focusElem: null,
      showMenuSelect: false,
    }
  },
  mounted () {
    if(/products/.test(window.location.href)) {
      this.focusElem = "Products"
    } else if(/projects/.test(window.location.href)) {
      this.focusElem = "Projects"
    } else if(/about/.test(window.location.href)) {
      this.focusElem = "About us"
    } else if(/news/.test(window.location.href)) {
      this.focusElem = "News"
    } else if(/certificates/.test(window.location.href)) {
      this.focusElem = "Certificates"
    } else if(/contacts/.test(window.location.href)) {
      this.focusElem = "Contacts"
    }
    window.addEventListener('scroll', this.onScroll)
    this.emitter.on("update-menu", place => {
      switch (place) {
        case 'home':
          this.focusElem = null
          break
        case 'products':
          this.focusElem = "Products"
          break
      }
    })
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0 || this.showMenuSelect === true) {
        return
      }  // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    linkClick(event) {
      if (this.showMenuSelect === true) {
        this.showMenuSelect = !this.showMenuSelect
      }
      switch (event.originalTarget.innerHTML) {
        case "Home":
          this.focusElem = null
          break
        case "Products":
          this.focusElem = "Products"
          break
        case "Projects":
          this.focusElem = "Projects"
          break
        case "About us":
          this.focusElem = "About us"
          break
        case "News":
          this.focusElem = "News"
          break
        case "Certificates":
          this.focusElem = "Certificates"
          break
        case "Contacts":
          this.focusElem = "Contacts"
          break
      }
    },
    resetHeader () {
      this.focusElem = null
    },
    changeNavBar(place) {
      this.emitter.emit("update-menu", place)
    },
    changeMenuVisibility() {
      this.showMenuSelect = !this.showMenuSelect
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 73px;
  width: 100%;
  margin: auto;
  display: flex;
  background-color: map.get($colors, "light-background-color");
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 2px -2px gray;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
  z-index: 2;
}
.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
.mauglo_logo {
  margin-left: 5%;
}
.mauglo_logo > img {
  width: 133px;
}
.router_link {
  display: inline-block;
  margin-right: 52px;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  position: relative;
}
.dot_below_elem::before {
  content:"";
  position:absolute;
  top:20px;
  background:black;
  width:5px;
  height:5px;
  border-radius:100%;
  left: 50%;
  transform: translate(0, -50%);
}
.router_link:nth-child(7) {
  margin-right: 0;
}
a.login_button {
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  font-size: 14px;
  width: 123px;
  height: 41px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;

  &:focus {
    color: black;
    background-color: white;
    border: 1px solid black;
  }
}
.focused_out {
  opacity: 0.5;
}
.menu_button {
  width: 40px;
  height: 28px;
  img {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
}
.right_side {
  display: none;
}
.full_screen_navigation {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: absolute;
  top: 0;
}
@media (max-width: 1300px) {
  .extra_home {
    display: none;
  }
}
@media (max-width: 1200px) {
  .router_link {
    margin-right: 20px;
  }
}
@media (max-width: 992px) {
  .router_link {
    display: none;
  }
  .login_button.single_button {
    display: none;
  }
  .right_side {
    display: flex;
    align-items: center;
    margin-right: 5%;
    a.login_button {
      margin-right: 50px;
    }
  }
  .header {
    height: 27px;
    width: calc(100% - 64px);
    margin: 32px auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a.white_login_button {
    text-decoration: none;
    text-transform: uppercase;
    color: black;
    font-size: 14px;
    width: 150px;
    height: 41px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: min(5vh, 40px) auto;
  }
  hr {
    width: calc(100% - 64px);
    margin: auto;
  }
  .menu_link {
    display: block;
    margin: auto;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    position: relative;
    margin-bottom: min(5vh, 40px);
    &:nth-child(1) {
      margin-top: min(10vh, 80px);
    }
  }
  .close_button:hover {
    cursor: pointer;
  }
}
@media (max-width: 576px) {
  .navbar {
    height: 48px;
  }
  .mauglo_logo > img {
    width: 95px;
  }
  .menu_button {
    width: 32px;
    height: 23px;
    img {
      width: 100%;
    }
  }
  .right_side {
    a.login_button {
      margin-right: 40px;
      width: 90px;
      height: 32px;
    }
  }
}
@media (max-width: 400px) {
  .right_side {
    a.login_button {
      display: none;
    }
  }
}
@media (max-width: 320px) {
  .mauglo_logo > img {
    width: 90px;
  }
  .right_side {
    a.login_button {
      margin-right: 25px;
      width: 70px;
      height: 32px;
    }
  }
}
@media (max-height: 600px) {
  .menu_link {
    font-size: 18px;
    margin-bottom: min(4vh, 32px);

    &:nth-child(1) {
      margin-top: min(6vh, 48px);
    }
  }
}
</style>