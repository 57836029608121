<template>
  <div class="how_works_container">
    <div class="description_container">
      <h3>{{ $t('how_does_it_work') }}</h3>
      <div class="number_in_circle">
        <p>{{ currentItem }}.</p>
      </div>
      <p class="p-4">{{ $t(currentDescription) }}</p>
      <div class="timer" ref="sentinalName">
        <div class="line_container">
          <div class="hr_box">
            <hr :style="'width:' + completionPercent1 + '%;'" class="line_1 fully_visible">
            <hr :style="'width:' + (40 - completionPercent1) + '%;'" class="line_1">
            <hr :style="'width:' + completionPercent2 + '%;'" class="fully_visible">
            <hr :style="'width:' + (20 - completionPercent2) + '%;'" class="line_2 ">
            <hr :style="'width:' + completionPercent3 + '%;'" class="fully_visible">
            <hr :style="'width:' + (20 - completionPercent3) + '%;'" class="line_3">
          </div>
          <p>0{{ currentItem }} / 03</p>
        </div>
        <div class="navigation_buttons">
          <img draggable="false" @click="leftClick" @mousedown="changeLeftArrow" @mouseup="changeLeftArrow" class="left_arrow" :class="leftArrow" :src="require(`@/assets/images/products/${left_arrow_src}.png`)">
          <img draggable="false" @click="rightClick" @mousedown="changeRightArrow" @mouseup="changeRightArrow" :class="rightArrow" :src="require(`@/assets/images/products/${right_arrow_src}.png`)">
        </div>
      </div>
    </div>
    <div class="img_container">
      <img :src="require(`@/assets/images/home/${currentImage}.png`)" alt="" loading="lazy">
    </div>
  </div>
</template>

<script>
export default {
  name: "TestTemplate",
  data() {
    return {
      currentItem: 1,
      currentDescription: 'how_works_paragraph_1',
      currentImage: 'how_works_1',
      completionPercent1: 0,
      completionPercent2: 0,
      completionPercent3: 0,
      manualOverride: false,
      line_one_timeout_id: 0,
      line_two_timeout_id: 0,
      main_data: [
        ['how_works_paragraph_1', 'how_works_1'],
        ['how_works_paragraph_2', 'how_works_2'],
        ['how_works_paragraph_3', 'how_works_3'],
      ],
      isIntersectingElement: false,
      right_arrow_pressed_down: false,
      left_arrow_pressed_down: false,
      right_arrow_src: "right_arrow",
      left_arrow_src: "right_arrow",
      is_initialized: false,
    }
  },
  watch: {
    isIntersectingElement: function() {
      if (this.manualOverride !== true) {
        if (this.currentItem === 1 && this.is_initialized === false) {
          this.line_one_timeout_id = setTimeout(() => {
            this.currentItem = 2
            this.currentDescription = this.main_data[1][0]
            this.currentImage = this.main_data[1][1]
            this.displayContent()
          }, 5000)
          this.is_initialized = true
          this.changeLineOne(0)
        }
      }
    },
  },
  methods: {
    displayContent() {
      if (this.manualOverride !== true) {
        if (this.currentItem === 1) {
          this.line_one_timeout_id = setTimeout(() => {
            this.currentItem = 2
            this.currentDescription = this.main_data[1][0]
            this.currentImage = this.main_data[1][1]
            this.displayContent()
          }, 5000)
          this.changeLineOne(0)
        } else if (this.currentItem === 2) {
          this.line_two_timeout_id = setTimeout(() => {
            this.currentItem = 3
            this.currentDescription = this.main_data[2][0]
            this.currentImage = this.main_data[2][1]
            this.displayContent()
          }, 3000)
          this.changeLineTwo(0)
        } else if (this.currentItem === 3) {
          this.changeLineThree(0)
        }
      }
    },
    changeLineOne(value) {
      if (value !== 40 && this.manualOverride !== true) {
        setTimeout(() => {
          value += 0.25
          this.completionPercent1 = value
          this.changeLineOne(value)
        }, 31)
      }
    },
    changeLineTwo(value) {
      if (value !== 20 && this.manualOverride !== true) {
        setTimeout(() => {
          value += 0.25
          this.completionPercent2 = value
          this.changeLineTwo(value)
        }, 37)
      }
    },
    changeLineThree(value) {
      if (value !== 20 && this.manualOverride !== true) {
        setTimeout(() => {
          value += 0.25
          this.completionPercent3 = value
          this.changeLineThree(value)
        }, 37)
      }
    },
    leftClick() {
      if (this.currentItem === 2) {
        clearTimeout(this.line_two_timeout_id)
        this.manualOverride = true
        setTimeout(() => {
          this.currentItem = 1
          this.completionPercent1 = 40
          this.completionPercent2 = 0
          this.completionPercent3 = 0
        }, 50)
        this.currentDescription = this.main_data[0][0]
        this.currentImage = this.main_data[0][1]
      } else if (this.currentItem === 3) {
        this.manualOverride = true
        setTimeout(() => {
          this.currentItem = 2
          this.completionPercent1 = 40
          this.completionPercent2 = 20
          this.completionPercent3 = 0
        }, 50)
        this.currentDescription = this.main_data[1][0]
        this.currentImage = this.main_data[1][1]
      }
    },
    rightClick() {
      if (this.currentItem === 1) {
        clearTimeout(this.line_one_timeout_id)
        this.manualOverride = true
        setTimeout(() => {
          this.currentItem = 2
          this.completionPercent1 = 40
          this.completionPercent2 = 20
          this.completionPercent3 = 0
        }, 50)
        this.currentDescription = this.main_data[1][0]
        this.currentImage = this.main_data[1][1]
      } else if (this.currentItem === 2) {
        clearTimeout(this.line_two_timeout_id)
        this.manualOverride = true
        setTimeout(() => {
          this.completionPercent1 = 40
          this.completionPercent2 = 20
          this.completionPercent3 = 20
        }, 50)
        this.currentItem = 3
        this.currentDescription = this.main_data[2][0]
        this.currentImage = this.main_data[2][1]
      }
    },
    changeLeftArrow() {
      if (this.currentItem !== 1) {
        if (this.left_arrow_src === "right_arrow_b") {
          this.left_arrow_src = "right_arrow"
        } else {
          this.left_arrow_src = "right_arrow_b"
        }
      }
    },
    changeRightArrow() {
      if (this.currentItem !== 3) {
        if (this.right_arrow_src === "right_arrow_b") {
          this.right_arrow_src = "right_arrow"
        } else {
          this.right_arrow_src = "right_arrow_b"
        }
      }
    },
    checkArrows() {
      this.right_arrow_src = "right_arrow"
      this.left_arrow_src = "right_arrow"

    }
  },
  mounted() {
    const sentinal = this.$refs.sentinalName
    const handler = (entries) => {
      if (entries[0].isIntersecting) {
        this.isIntersectingElement = true
      }
      else {
        this.isIntersectingElement = false
      }
    }
    const observer = new window.IntersectionObserver(handler)
    observer.observe(sentinal)

    window.addEventListener('mouseup', this.checkArrows)
  },
  computed: {
    leftArrow() {
      return {
        'focused_out': this.currentItem === 1
      }
    },
    rightArrow() {
      return {
        'focused_out': this.currentItem === 3
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.how_works_container {
  width: 100%;
  margin: min(15vh, 120px) 0;
  display: flex;
}
.description_container {
  width: 50%;
  padding-left: 8%;

  p.p-4 {
    width: 70%;
  }
}
.img_container {
  width: 50%;
  padding-left: 8%;
  margin-top: min(5vh, 40px);
}
.number_in_circle {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: min(6vh,48px) 0 min(3vh, 24px) 10px;

  p {
    font-size: 14px;
    color: white;
  }
}
.timer {
  display: flex;

  .line_container {
    width: 60%;

    .hr_box {
      margin-top: min(7vh, 56px);
      display: flex;

      .line_1 {
        width: 40%;
        opacity: 0.2;
      }
      .line_2, .line_3 {
        width: 20%;
        opacity: 0.2;
      }
      .fully_visible:nth-child(3), .fully_visible:nth-child(5) {
        margin-left: 2%;
      }
      .fully_visible {
        width: 0;
        opacity: 1;
      }
    }
    p {
      font-size: 14px;
      margin-top: min(2vh, 16px);
    }
  }

  .navigation_buttons {
    width: 40%;
    margin-top: min(7vh, 56px);
    padding-left: 5%;

    .left_arrow {
      transform: rotate(0.5turn);
      margin-right: 20px;
    }

    .focus_right_img, .focus_left_img {
      display: none;
    }

    img {
      width: 52px;
      cursor: pointer;
      border-radius: 100%;
    }
    img.focused_out {
      opacity: 0.3;
      cursor: auto;
    }
  }
}
@media (max-width: 1200px) {
  .description_container {
    width: 60%;
    padding-left: 8%;

    p.p-4 {
      width: 70%;
    }
  }
  .img_container {
    width: 40%;
    padding-left: 5%;
    margin-top: min(5vh, 40px);
    img {
      width: 100%;
    }
  }
  .timer {
    display: flex;

    .line_container {
      width: 70%;
    }

    .navigation_buttons {
      width: 30%;
      margin-top: min(7vh, 56px);
      padding-left: 0;

    }
  }
}
@media (max-width: 992px) {
  .img_container {
    padding-left: 0;
  }
  .timer {
    display: flex;

    .line_container {
      width: 60%;
    }

    .navigation_buttons {
      width: 130px;
      margin-top: min(7vh, 56px);
      padding-left: 0;

      .left_arrow {
        transform: rotate(0.5turn);
        margin-right: 20px;
      }
      img {
        width: 52px;
        cursor: pointer;
      }
      img.focused_out {
        opacity: 0.3;
        cursor: auto;
      }
    }
  }
}
@media (max-width: 768px) {
  .how_works_container {
    margin: min(12vh, 96px) 0;
    flex-direction: column-reverse;
  }
  .description_container {
    width: 100%;
    padding-left: 0;

    p.p-4 {
      width: 100%;
    }
  }
  .img_container {
    width: 100%;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: min(5vh, 40px);
    img {
      width: 90%;
      margin-left: 5%;
    }
  }
  .number_in_circle {
    width: 30px;
    height: 30px;
    background-color: black;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: min(6vh,48px) 0 min(3vh, 24px) 10px;

    p {
      font-size: 14px;
      color: white;
    }
  }
}
@media (max-width: 400px) {
  .img_container {
    width: 100%;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: min(5vh, 40px);
    img {
      width: 100%;
      margin-left: 0;
    }
  }
  .timer {
    display: block;

    .line_container {
      width: 70%;
    }

    .navigation_buttons {
      position: relative;
      bottom: min(5vh, 40px);
      left: 50%;


    }
  }
  .how_works_container {
    margin-bottom: min(5vh, 40px);
  }
}
</style>