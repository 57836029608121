<template>
  <div class="color_container">
    <div class="benefits">
      <div class="benefits_heading">
        <h3>{{ $t('benefits') }}</h3>
      </div>
      <div class="great_possibilities">
        <div class="possibilities_elem">
          <div class="possibilities_img_elem">
            <img class="logo_img_type_one" src="@/assets/control_logo.png" alt="" loading="lazy">
          </div>
          <h4 class="p-3">{{ $t('control') }}</h4>
        </div>
        <div class="possibilities_elem">
          <div class="possibilities_img_elem">
            <img class="logo_img_type_two" src="@/assets/remote_logo.png" alt="" loading="lazy">
          </div>
          <h4 class="p-3">{{ $t('remote_control') }}</h4>
        </div>
        <div class="possibilities_elem">
          <div class="possibilities_img_elem">
            <img class="logo_img_type_two" src="@/assets/savings_logo.png" alt="" loading="lazy">
          </div>
          <h4 class="p-3">{{ $t('savings') }}</h4>
        </div>
        <div class="possibilities_elem">
          <div class="possibilities_img_elem">
            <img class="logo_img_type_two" src="@/assets/remote_logo.png" alt="" loading="lazy">
          </div>
          <h4 class="p-3">{{ $t('quick_response') }}</h4>
        </div>
        <div class="possibilities_elem">
          <div class="possibilities_img_elem">
            <img class="logo_img_type_three" src="@/assets/peace_logo.png" alt="" loading="lazy">
          </div>
          <h4 class="p-3">{{ $t('peace_of_mind') }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeBenefits',
}
</script>

<style lang="scss" scoped>

.color_container {
  background-color: map.get($colors, "dark-background-color");
}

.benefits {
  width: 100%;
  display: flex;
}
.benefits_heading {
  width: 50%;
  padding-left: 8%;
  & > h3 {
    margin-top: min(10vh, 80px);
  }
}
.great_possibilities {
  width: 50%;
  margin-top: min(12vh, 96px);
  margin-bottom: min(5vh, 40px);
  display: flex;
  flex-wrap: wrap;
  .possibilities_elem {
    width: 200px;
    margin-bottom: min(7vh, 56px);
  }
}
.possibilities_img_elem {
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo_img_type_one {
    width: 40px;
  }
  .logo_img_type_two {
    width: 35px;
  }
  .logo_img_type_three {
    width: 53px;
  }
}
h4 {
  width: 100px;
  margin-top: min(2vh, 14px);
  line-height: 120%;
}
@media (max-width: 1350px) {
  .benefits {
    width: 90%;
    margin: auto;
  }
  .benefits_heading {
    width: 30%;
    padding-left: 0;
  }
  .great_possibilities {
    width: 70%;
    margin-top: min(20vh, 160px);
    justify-content: space-between;
    .possibilities_elem {
      width: 150px;
    }
  }
}
@media (max-width: 992px) {
  .benefits {
    display: block;
  }
  .benefits_heading {
    padding-top: min(10vh, 80px);
    & > h3 {
      margin-top: 0;
    }
  }
  .great_possibilities {
    width: 100%;
    margin-top: min(10vh, 80px);
    margin-bottom: min(5vh, 40px);
    justify-content: left;
    .possibilities_elem {
      width: 180px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
@media (max-width: 576px) {
  .benefits {
    display: block;
  }
  .benefits_heading {
    padding-top: min(10vh, 80px);
    & > h3 {
      margin-top: 0;
    }
  }
  .great_possibilities {
    width: 100%;
    margin-top: min(10vh, 80px);
    margin-bottom: min(5vh, 40px);
    justify-content: left;
    .possibilities_elem {
      width: 150px;
      display: flex;
      justify-content: center;
    }
  }
  .possibilities_img_elem {
    width: 80px;
    height: 80px;

    .logo_img_type_one {
      width: 35px;
    }
    .logo_img_type_two {
      width: 30px;
    }
    .logo_img_type_three {
      width: 47px;
    }
  }
}
@media (max-width: 333px) {
  .great_possibilities {
    .possibilities_elem {
      width: 120px;
      display: block;
    }
  }
}
</style>