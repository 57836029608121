<template>
  <div class="standard_main_container">
    <h1>{{ $t('home_header') }}</h1>
    <img class="bar_lines original" src="@/assets/images/home/home_line_bar.png">
    <img class="bar_lines shorter" src="@/assets/images/home/short_home_line_bar.png">
    <img class="main_img" src="@/assets/images/home/home_1.png">
    <h4>{{ $t('home_paragraph') }}</h4>
    <HomeSystems />
    <HomeWorks :width="width" :height="height"/>
    <HomeBenefits v-if="width > 1350" />
  </div>
  <HomeBenefits v-if="width <= 1350" />
</template>

<script>
import HomeBenefits from "@/components/home/HomeBenefits";
import HomeSystems from "@/components/home/HomeSystems";
import HomeWorks from "@/components/home/HomeWorks";

export default {
  name: 'HomeView',
  components: {
    HomeBenefits,
    HomeSystems,
    HomeWorks
  },
  methods: {
    changeNavBar(place) {
      this.emitter.emit("update-menu", place)
    },
    onResize() {
      this.width = window.innerWidth
      this.height = window.innerHeight
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize)

  },
  unmounted() {
    window.removeEventListener("resize", this.onResize)
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
}
</script>

<style lang="scss" scoped>

.standard_main_container {
  position: relative;
}
img.main_img {
  width: 100%;
}
.bar_lines {
  position: absolute;
  top: calc(80px + min(10vh, 80px));
  right: min(7%, 120px);
  width: min(230px, 25%);
}
h1 {
  width: 63%;
  margin: min(8vh, 64px) 0;
}
h4 {
  width: 85%;
  margin: min(10vh, 80px) auto;
}
.shorter {
  display: none;
}
@media (max-width: 1050px) {
  .original {
    display: none;
  }
  .shorter {
    display: block;
  }
}
@media (max-width: 992px) {
  h4 {
    width: 100%;
  }
}
@media (max-width: 863px) {
  .bar_lines {
    top: calc(180px + min(10vh, 80px));
  }
}
@media (max-width: 700px) {
  h1 {
    margin: min(6vh, 48px) 0;
  }
}
@media (max-width: 641px) {
  h1 {
    width: 100%;
    margin-bottom: min(14vh, 112px);
  }
  .bar_lines {
    top: calc(200px + min(10vh, 80px));
  }
}
@media (max-width: 576px) {
  h1 {
    width: 100%;
    margin: min(2vh, 16px) 0;
    margin-bottom: min(12vh, 94px);
  }
  .bar_lines {
    top: calc(140px + min(10vh, 80px));
    right: 0;
    width: min(150px, 30%);
  }
  h4 {
    margin: min(5vh, 40px) auto;
  }
}
@media (max-width: 494px) {
  h1 {
    margin-bottom: min(14vh, 112px);
  }
  .bar_lines {
    top: calc(130px + min(10vh, 80px));
  }
}
@media (max-width: 430px) {
  h1 {
    margin-bottom: min(12vh, 96px);
  }
}
@media (max-width: 374px) {
  h1 {
    margin-bottom: min(4vh, 32px);
  }
  .bar_lines {
    top: calc(150px + min(10vh, 80px));
  }
}
@media (max-width: 360px) {
  .bar_lines {
    top: calc(210px + min(10vh, 80px));
  }
}
</style>
