import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import mitt from 'mitt'
const emitter = mitt()
import axios from 'axios'
import VueAxios from 'vue-axios'
const app = createApp(App)

app.config.globalProperties.emitter = emitter;
app.use(VueAxios, axios)
app.use(i18n).use(router).mount('#app')
