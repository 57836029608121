<template>
  <div class="privacy_rules">
    <img src="@/assets/close_button.png" @click="privacyRules(false)">
    <p>
      {{ $t('privacy_rules') }}
      <router-link to="/privacy_rules" @click="changeNavBar('home')">
        <a>{{ $t('privacy_policy') }}.</a>
      </router-link>
    </p>
    <a href="" class="black_button" @click.prevent="privacyRules(true)">{{ $t('accept_and_continue') }}</a>
  </div>
</template>

<script>
export default {
  name: "PrivacyRulesComponent",
  methods: {
    privacyRules(decision) {
      localStorage.setItem('MaugloPrivacyRules', decision)
      this.$emit('closePrivacyRules', decision)
    },
    changeNavBar(place) {
      this.emitter.emit("update-menu", place)
    }
  }
}
</script>

<style lang="scss" scoped>

.privacy_rules {
  width: 500px;
  border-radius: 6px;
  padding: 25px;
  color: white;
  background-color: black;
  position: fixed;
  bottom: min(5vh, 40px);
  right: calc((100% - 1260px)/2);

  p {
    font-size: 16px;
    width: 93%;

    a {
      color: white;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
  a.black_button {
    background-color: white;
    color: black;
    font-weight: 400;
    width: 230px;
    margin-top: 25px;

    &:active {
      border: 1px solid white;
      background-color: black;
      color: white;
    }
  }
  img {
    float: right;
    cursor: pointer;
    width: 16px;

    &:active {
      opacity: 0.5;
    }
  }
}
@media (max-width: 1350px) {
  .privacy_rules {
    right: 0;
  }
}
@media (max-width: 500px) {
  .privacy_rules {
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 6px;
    padding: 25px;

    p {
      font-size: 16px;
      width: 90%;
    }
    a.black_button {
      width: 100%;
    }
  }
}
</style>